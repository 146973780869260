.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-uppercase {
  text-transform: uppercase;
}

.text-green {
  color: rgb(72, 184, 101);
}
.text-red {
  color: rgb(184, 76, 72);
}
.text-yellow {
  color: rgb(204, 179, 95);
}
