/* inspired by: https://css-tricks.com/responsive-data-tables/ */

.table-responsible td .tdBefore {
  display: none;
}

@media screen and (max-width: 80em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none; for accessibility)
  */

  .table-responsible table,
  .table-responsible thead,
  .table-responsible tbody,
  .table-responsible th,
  .table-responsible td,
  .table-responsible tr {
    display: block;
  }

  .table-responsible thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-responsible tbody tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: repeat(auto-fit, 1fr);
    flex-wrap: wrap;
    width: 100%;

    &:nth-of-type(odd) {
      background: var(--gglass-colors-gray-100);

      & td {
        background: var(--gglass-colors-gray-100);
      }
    }

    border-radius: 8px;
    padding: 1em 0;
  }

  .table-responsible td.pivoted {
    /* Behave like a "row" */
    flex: 1;
    width: 100%;

    border: none !important;
    // position: relative;
    // padding-bottom: 1em;
    // padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    // border-bottom: 1px solid lightgrey !important;
  }

  .table-responsible td .tdBefore {
    /* Now like a table header */
    // position: absolute;
    display: block;

    /* Top/left values mimic padding */
    // left: 1rem;
    // width: calc(50% - 20px);
    text-transform: uppercase;
    margin-bottom: 0.5em;
    font-size: var(--gglass-fontSizes-xs);

    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: var(--gglass-fontWeights-semibold);
  }
}
