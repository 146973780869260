ul.pagination {
  box-sizing: border-box;
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    user-select: none;
    color: #626262;
    direction: ltr;
    list-style: none;
    box-sizing: border-box;
    display: inline-block;
    user-select: none;
    cursor: pointer;
    background-color: rgba(44, 44, 44, 0.08);
    border-radius: 0.5rem;
    margin: 0 0.2rem;
    user-select: none;
    -webkit-tap-highlight-color: unset;

    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.active):hover {
      background-color: rgba(44, 44, 44, 0.13);
    }

    a {
      text-decoration: none;
      color: var(--gglass-colors-black-100);

      padding: 0.5rem;

      font-weight: bold;
    }
  }

  .previous {
    margin-right: 0.4rem;
  }

  .next {
    margin-left: 0.4rem;
  }

  .active {
    background: var(--gglass-colors-yellow-400);

    a {
      color: var(--gglass-colors-black-400);
    }
  }
}
