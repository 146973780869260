.ui.action.input ~ span,
.ui.form .field > span,
.ui.grid.form .field > span,
.ui.grid.form input ~ span,
.ui.grid input ~ span {
  font-size: 1.2rem;
  color: rgb(159, 58, 56);
}

.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'],
.ui.form textarea,
.ui.form select,
.ui.selection.dropdown {
  // border-radius: 0.8rem !important;
  transition: all 0.3s ease;
  // color: rgb(44, 44, 44);
}

// .ui.multiple.dropdown > .label {
// border-radius: 0.6rem !important;
// }

input:focus,
select:focus,
textarea:focus,
.ui.selection.dropdown:focus {
  // border-color: #7367f0 !important;
  // border-radius: 0.8rem !important;
}

// .ui.labeled.input {
//   .label {
//     border-top-left-radius: 0.8rem;
//     border-bottom-left-radius: 0.8rem;
//   }

//   input,
//   select {
//     border-top-right-radius: 0.8rem;
//     border-bottom-right-radius: 0.8rem;
//   }
// }

// .ui.labeled.input select
