.header-shadow {
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );

  display: block;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 11;
}

.ui.dividing.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
