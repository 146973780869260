.m-xxs {
  margin: 2px 4px !important;
}
.m-xs {
  margin: 5px !important;
}
.m-sm {
  margin: 10px !important;
}
.m {
  margin: 15px !important;
}
.m-md {
  margin: 20px !important;
}
.m-lg {
  margin: 30px !important;
}
.m-xl {
  margin: 50px !important;
}
.m-l-xxs {
  margin-left: 1px !important;
}
.m-l-xs {
  margin-left: 5px !important;
}
.m-l-sm {
  margin-left: 10px !important;
}
.m-l {
  margin-left: 15px !important;
}
.m-l-md {
  margin-left: 20px !important;
}
.m-l-lg {
  margin-left: 30px !important;
}
.m-l-xl {
  margin-left: 40px !important;
}
.m-l-n-xxs {
  margin-left: -1px !important;
}
.m-l-n-xs {
  margin-left: -5px !important;
}
.m-l-n-sm {
  margin-left: -10px !important;
}
.m-l-n {
  margin-left: -15px !important;
}
.m-l-n-md {
  margin-left: -20px !important;
}
.m-l-n-lg {
  margin-left: -30px !important;
}
.m-l-n-xl {
  margin-left: -40px !important;
}
.m-t-none {
  margin-top: 0 !important;
}
.m-t-xxs {
  margin-top: 1px !important;
}
.m-t-xs {
  margin-top: 5px !important;
}
.m-t-sm {
  margin-top: 10px !important;
}
.m-t {
  margin-top: 15px !important;
}
.m-t-md {
  margin-top: 20px !important;
}
.m-t-lg {
  margin-top: 30px !important;
}
.m-t-xl {
  margin-top: 40px !important;
}
.m-t-n-xxs {
  margin-top: -1px !important;
}
.m-t-n-xs {
  margin-top: -5px !important;
}
.m-t-n-sm {
  margin-top: -10px !important;
}
.m-t-n {
  margin-top: -15px !important;
}
.m-t-n-md {
  margin-top: -20px !important;
}
.m-t-n-lg {
  margin-top: -30px !important;
}
.m-t-n-xl {
  margin-top: -40px !important;
}
.m-r-none {
  margin-right: 0 !important;
}
.m-r-xxs {
  margin-right: 1px !important;
}
.m-r-xs {
  margin-right: 5px !important;
}
.m-r-sm {
  margin-right: 10px !important;
}
.m-r {
  margin-right: 15px !important;
}
.m-r-md {
  margin-right: 20px !important;
}
.m-r-lg {
  margin-right: 30px !important;
}
.m-r-xl {
  margin-right: 40px !important;
}
.m-r-n-xxs {
  margin-right: -1px !important;
}
.m-r-n-xs {
  margin-right: -5px !important;
}
.m-r-n-sm {
  margin-right: -10px !important;
}
.m-r-n {
  margin-right: -15px !important;
}
.m-r-n-md {
  margin-right: -20px !important;
}
.m-r-n-lg {
  margin-right: -30px !important;
}
.m-r-n-xl {
  margin-right: -40px !important;
}
.m-b-none {
  margin-bottom: 0 !important;
}
.m-b-xxs {
  margin-bottom: 1px !important;
}
.m-b-xs {
  margin-bottom: 5px !important;
}
.m-b-sm {
  margin-bottom: 10px !important;
}
.m-b {
  margin-bottom: 15px !important;
}
.m-b-md {
  margin-bottom: 20px !important;
}
.m-b-lg {
  margin-bottom: 30px !important;
}
.m-b-xl {
  margin-bottom: 40px !important;
}
.m-b-n-xxs {
  margin-bottom: -20px !important;
}
.m-b-n-xs {
  margin-bottom: -15px !important;
}
.m-b-n-sm {
  margin-bottom: -10px !important;
}
.m-b-n {
  margin-bottom: -15px !important;
}
.m-b-n-md {
  margin-bottom: -20px !important;
}
.m-b-n-lg {
  margin-bottom: -30px !important;
}
.m-b-n-xl {
  margin-bottom: -40px !important;
}
.m-side-xxs {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.m-none {
  margin: 0 !important;
}
.m-auto {
  margin: 0 auto !important;
}
.padder {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.padder-sm {
  padding: 8px !important;
}
.padder-l-r-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.padder-l {
  padding-left: 30px !important;
}
.padder-v {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.no-padder {
  padding: 0 !important;
}
.margin-none {
  margin: none;
}
