.flex {
  display: flex;
  align-items: center;
  justify-content: center;

  &.direction-colum {
    flex-direction: column;
  }

  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.justify-start {
    justify-items: flex-start;
  }
  &.justify-end {
    justify-items: flex-end;
  }
}
