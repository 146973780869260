.swal-modal {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  font-family: Poppins, sans-serif;
}
.swal-modal .swal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swal-modal .swal-text {
  text-align: center;
}
