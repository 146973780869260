:focus {
  outline-color: #7367f0;
}

.ui.secondary.menu,
.ui.tab {
  font-size: 13px;
}

.ui.segment {
  box-shadow: 0 0 15px 0 rgba(118, 118, 118, 0.2) !important;
  border: none !important;
}

@import './table.scss';
@import './button.scss';
@import './label.scss';
@import './dropdown.scss';
@import './utils.scss';
@import './form.scss';
@import './sweetalert.scss';
@import './toastify.scss';
@import './pagination.scss';
@import './margins.scss';
@import './modal.scss';
@import './header.scss';
@import './text.scss';
@import './flexbox.scss';
@import './table-responsible.scss';
