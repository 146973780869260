// .ui.button {
//   border-radius: 0.8rem;
// }
// .ui.button.red {
//   box-shadow: 0 0 15px 0 rgba(219, 40, 40, 0.6) !important;
// }
// .ui.button.orange {
//   box-shadow: 0 0 15px 0 rgba(242, 113, 28, 0.6) !important;
// }
// .ui.button.yellow {
//   box-shadow: 0 0 15px 0 rgba(251, 190, 8, 0.6) !important;
// }
// .ui.button.olive {
//   box-shadow: 0 0 15px 0 rgba(181, 204, 24, 0.6) !important;
// }
// .ui.button.green {
//   box-shadow: 0 0 15px 0 rgba(33, 186, 69, 0.6) !important;
// }
// .ui.button.teal {
//   box-shadow: 0 0 15px 0 rgba(0, 181, 173, 0.6) !important;
// }
// .ui.button.blue {
//   box-shadow: 0 0 15px 0 rgba(30, 108, 168, 0.6) !important;
// }
// .ui.button.blue.active {
//   background-color: rgb(22, 86, 135) !important;
// }
// .ui.button.violet {
//   box-shadow: 0 0 15px 0 rgba(100, 53, 201, 0.6) !important;
// }
// .ui.button.purple {
//   box-shadow: 0 0 15px 0 rgba(121, 97, 249, 0.6) !important;
// }
// .ui.button.pink {
//   box-shadow: 0 0 15px 0 rgba(224, 57, 151, 0.6) !important;
// }
// .ui.button.brown {
//   box-shadow: 0 0 15px 0 rgba(165, 103, 63, 0.6) !important;
// }
// .ui.button.grey {
//   box-shadow: 0 0 15px 0 rgba(118, 118, 118, 0.6) !important;
// }
// .ui.button.black {
//   box-shadow: 0 0 15px 0 rgba(27, 28, 29, 0.6) !important;
// }

.ui.button.filter-dashboard {
  margin: 4px;
}
